import React from "react"

import "./landing_page.css"
import useWindowDimensions from "../../APIs/window_dimensions"

import landingBlob from "../../images/LandingBlob.svg"

const BackgroundBlob = props => {
  return (
    <div className="blob-wrapper">
      <img className="blob" src={landingBlob} alt="" />
    </div>
  )
}

const LandingPage = () => {
  const { height, width } = useWindowDimensions()
  const isMobile = width / height <= 16 / 14

  return (
    <div className="landing-page">
      <BackgroundBlob isMobile={isMobile} height={height} />
      <section id="landing-page">
        <div className="main-section">
          <div className="left-block">
            <div className="text-block">
              <h2 className="company">MCubed</h2>
              <div className="main-heading">WEBSITE</div>
              <div className="main-heading">DESIGN &</div>
              <div className="main-heading">DEVELOPMENT</div>
              <div className="sub-heading">
                Business driven design that you can trust. Your brand is unique,
                your website should be as well.
              </div>
            </div>
          </div>
          <div className="right-block">
            <div className="landing-img" />
          </div>
        </div>
      </section>
    </div>
  )
}

export default LandingPage
